<template>
  <div>
    <CommonIndex
      :pageType="pageType"
      @showDetail="showDetail"
      :showThreshold="true"
    />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import CommonIndex from '@/views/safety-device/component/common-index';
import { createDrawer } from '@triascloud/x-components';
import electricityMeterDetail from './electricity-meter-detail';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';

@Component({
  components: {
    CommonIndex,
  },
})
export default class ElectricityMeter extends Vue {
  // 塔吊安全监测
  pageType = pageTypeEnum.elecMonitor;

  modalDrawer = null;
  handleCloseModalDrawer() {
    this.modalDrawer.handleClosed();
  }
  showDetail(record) {
    this.modalDrawer = createDrawer(
      () => (
        <electricityMeterDetail
          detailData={record}
          pageType={this.pageType}
          close={this.handleCloseModalDrawer}
        />
      ),
      {
        title: this.$t('safety.deviceDetail'),
        width: '720px',
        wrapClassName: 'deviceDetail',
      },
    );
  }
}
</script>

<style lang="less" module>
@import '@/views/safety-device/component/safety.less';
</style>
